import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BillingAddress, Customer, Order } from '../cart/cart.state';

export const UserActions = createActionGroup({
  source: 'Users',
  events: {
    'Get User': emptyProps(),
    'Set User': props<{
      user: Customer;
    }>(),
    'Update Billing Address': props<Partial<BillingAddress>>(),
    'Load Orders': emptyProps(),
    'Set Orders': props<{ orders: Order[] }>(),
    'Update Password': props<{ password: string }>(),
    'Remove User': emptyProps(),
    Logout: emptyProps(),
  },
});
